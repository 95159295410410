import {AfterViewInit, Directive, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[appReservationStatusColor]'
})
export class ReservationStatusColorDirective  implements  AfterViewInit {
    @HostBinding('class') class!: string;

    @Input()
    public reservationStatus!: string;

    ngAfterViewInit(): void {
        if (this.reservationStatus === 'ANNULEE') {
            this.class = 'p-button-danger';
        } else if (this.reservationStatus === 'ENCOURS') {
            this.class = 'p-button-warning';
        } else {
            this.class = 'p-button-success';
        }
    }

}
