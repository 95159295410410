import {AfterViewInit, Directive, HostBinding, Input} from '@angular/core';

@Directive({
    selector: '[appChambreStatusColor]'
})
export class ChambreStatusColorDirective implements  AfterViewInit {

    constructor() { }

    @HostBinding('class') class!: string;

    @Input()
    public chambreStatus!: string;

    ngAfterViewInit(): void {
        if (this.chambreStatus === 'LIBRE') {
            this.class = 'p-button-danger';
        } else if (this.chambreStatus === 'RESERVEE') {
            this.class = 'p-button-warning';
        } else {
            this.class = 'p-button-success';
        }
    }

}
