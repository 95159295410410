import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import {HttpInterceptorProviders} from "./core/interceptor/httpInterceptor.config";
import {HttpClientModule} from "@angular/common/http";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import {IConfig, NgxMaskModule} from "ngx-mask";
import {ngxLoadingAnimationTypes, NgxLoadingModule} from "ngx-loading";

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        HttpClientModule,
        NgxExtendedPdfViewerModule,
      NgxMaskModule.forRoot(maskConfigFunction),
      NgxLoadingModule.forRoot({
        animationType: ngxLoadingAnimationTypes.threeBounce,
        backdropBackgroundColour: "rgba(0,0,0,0.1)",
        backdropBorderRadius: "10px",
        primaryColour: "#eb1717",
        secondaryColour: "#eecece",
        tertiaryColour: "#2b731b",
      }),
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        HttpInterceptorProviders,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
