import {AfterViewInit, Directive, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[appStatusColor]'
})
export class StatusColorDirective implements AfterViewInit{
    @HostBinding('class') class!: string;

    @Input()
    status!: string;

    @Input()
    typeStatus!: string;

    constructor() {
    }

    ngAfterViewInit(): void {
        this.class = this.getBtnClass(this.typeStatus, this.status);
    }

    getBtnClass(type: string, status: string): string {
        switch (type) {
            case 'CHAMBRES':
                if (status === 'LIBRE') {
                    return 'p-button-danger';
                } else if (status === 'RESERVEE') {
                    return 'p-button-warning';
                } else {
                    return 'p-button-success';
                }
            case 'RESERVATIONS':
                if (status === 'ANNULEE') {
                    return 'p-button-danger';
                } else if (status === 'ENCOURS') {
                    return 'p-button-warning';
                } else {
                    return 'p-button-success';
                }
            case 'LOCATIONS':
                if (status === 'ENCOURS') {
                    return 'p-button-warning';
                } else if (status === 'CLOTUREE') {
                    return 'p-button-infos';
                } else {
                    return 'p-button-success';
                }
            case 'CAISSES':
                if (status === 'OUVERTE') {
                    return 'p-button-success';
                } else if (status === 'FERMEE') {
                    return 'p-button-danger';
                } else {
                    return '';
                }
            default:
                return '';
        }
    }
}
