import {AfterViewInit, Directive, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[appLocationStatusColor]'
})
export class LocationStatusColorDirective  implements  AfterViewInit {
    @HostBinding('class') class!: string;

    @Input()
    public locationStatus!: string;

    ngAfterViewInit(): void {
        if (this.locationStatus === 'ENCOURS') {
            this.class = 'p-button-warning';
        } else if (this.locationStatus === 'CLOTUREE') {
            this.class = 'p-button-infos';
        } else {
            this.class = 'p-button-success';
        }
    }

}
