import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import {RouteInfos} from "../features/models/routeInfos.model";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: RouteInfos[] = [];

    ngOnInit() {
        this.model = [
            {
                label: 'Tableau de bord',
                icon: 'pi pi-home',
                authorities: [],
                items: [
                    {
                        label: 'Accueil',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/'],
                        authorities: [],
                    },
                ]
            },
            {
                label: 'Métiers',
                icon: 'pi pi-home',
                authorities: ['ROLE_LOCATIONS', 'ROLE_RESERVATIONS', 'ROLE_PAIEMENTS', 'ROLE_CLIENTS', 'ROLE_HOTELS_ANNEXES', 'ROLE_CHAMBRES', 'ROLE_SALLES', 'ROLE_COMPTABILITE', 'ROLE_STATISTIQUES'],
                items: [
                    {
                        label: 'Clients',
                        icon: 'pi pi-users',
                        routerLink: ['/clients'],
                        authorities: ['ROLE_CLIENTS'],
                    },
                    // {
                    //     label: 'Bons',
                    //     icon: 'pi pi-id-card',
                    //     routerLink: ['/locations'],
                    //     authorities: ['ROLE_LOCATIONS'],
                    // },
                    {
                        label: 'Cotations',
                        icon: 'pi pi-euro',
                        authorities: ['ROLE_COMPTABILITE'],
                        items: [
                            {
                                label: 'Cotations ECOMAF',
                                icon: 'pi pi-clock',
                                routerLink: ['/cotations/cotations-ecomaf'],
                                authorities: [],
                            },
                            // {
                            //     label: 'Cotations Fournisseurs',
                            //     icon: 'pi pi-calculator',
                            //     routerLink: ['/caisses'],
                            //     authorities: [],
                            // },
                        ],
                    },
                    // {
                    //     label: 'PO',
                    //     icon: 'pi pi-euro',
                    //     authorities: ['ROLE_COMPTABILITE'],
                    //     items: [
                    //         {
                    //             label: 'PO Clients',
                    //             icon: 'pi pi-clock',
                    //             routerLink: ['/caisses/sessions'],
                    //             authorities: [],
                    //         },
                    //         {
                    //             label: 'PO ECOMAF',
                    //             icon: 'pi pi-calculator',
                    //             routerLink: ['/caisses'],
                    //             authorities: [],
                    //         },
                    //     ],
                    // },
                    // {
                    //     label: 'Operations',
                    //     icon: 'pi pi-euro',
                    //     authorities: ['ROLE_COMPTABILITE'],
                    //     items: [
                    //         {
                    //             label: 'BL',
                    //             icon: 'pi pi-clock',
                    //             routerLink: ['/caisses/sessions'],
                    //             authorities: [],
                    //         },
                    //         {
                    //             label: 'LTA',
                    //             icon: 'pi pi-calculator',
                    //             routerLink: ['/caisses'],
                    //             authorities: [],
                    //         },
                    //         {
                    //             label: 'Chargements',
                    //             icon: 'pi pi-calculator',
                    //             routerLink: ['/caisses'],
                    //             authorities: [],
                    //         },
                    //     ],
                    // },
                    {
                        label: 'Factures',
                        icon: 'pi pi-euro',
                        authorities: ['ROLE_COMPTABILITE'],
                        items: [
                            {
                                label: 'Factures Com',
                                icon: 'pi pi-clock',
                                routerLink: ['/facture-commerciale'],
                                authorities: [],
                            },
                            {
                                label: 'Factures définitives',
                                icon: 'pi pi-calculator',
                                routerLink: ['/caisses'],
                                authorities: [],
                            },
                        ],
                    },
                    // {
                    //     label: 'Caisses',
                    //     icon: 'pi pi-euro',
                    //     authorities: ['ROLE_COMPTABILITE'],
                    //     items: [
                    //         {
                    //             label: 'Sessions de Caisse',
                    //             icon: 'pi pi-clock',
                    //             routerLink: ['/caisses/sessions'],
                    //             authorities: [],
                    //         },
                    //         {
                    //             label: 'Opérations',
                    //             icon: 'pi pi-calculator',
                    //             routerLink: ['/caisses'],
                    //             authorities: [],
                    //         },
                    //         {
                    //             label: 'Avances',
                    //             icon: 'pi pi-calculator',
                    //             routerLink: ['/caisses'],
                    //             authorities: [],
                    //         },
                    //         {
                    //             label: 'Paiements',
                    //             icon: 'pi pi-calculator',
                    //             routerLink: ['/caisses'],
                    //             authorities: [],
                    //         },
                    //     ],
                    // },
                    {
                        label: 'Statistiques',
                        icon: 'pi pi-chart-line',
                        routerLink: ['/statistiques'],
                        authorities: ['ROLE_STATISTIQUES'],
                    },
                ]
            },
            {
                label: 'Paramètres',
                icon: 'pi pi-cog',
                authorities: ['ROLE_PARAMETRES', 'ROLE_UTILISATEURS'],
                items: [
                    {
                        label: 'Paramètres',
                        icon: 'pi pi-cog',
                        authorities: ['ROLE_PARAMETRES'],
                        items: [
                            {
                                label: 'Fournisseurs',
                                icon: 'pi pi-hashtag',
                                routerLink: ['/fournisseurs'],
                                authorities: [],
                            },
                            {
                                label: 'Banques',
                                icon: 'pi pi-hashtag',
                                routerLink: ['/parametres/banques'],
                                authorities: [],
                            },
                            // {
                            //     label: 'Marchandises',
                            //     icon: 'pi pi-hashtag',
                            //     routerLink: ['/pays/villes'],
                            //     authorities: [],
                            // },
                            // {
                            //     label: 'Devises',
                            //     icon: 'pi pi-hashtag',
                            //     routerLink: ['/parametres/type-chambres'],
                            //     authorities: [],
                            // },
                            // {
                            //     label: 'Camions',
                            //     icon: 'pi pi-hashtag',
                            //     routerLink: ['/parametres/type-salles'],
                            //     authorities: [],
                            // },
                            // {
                            //     label: 'Pays',
                            //     icon: 'pi pi-hashtag',
                            //     routerLink: ['/parametres/type-documents'],
                            //     authorities: [],
                            // },
                            // {
                            //     label: 'Ports',
                            //     icon: 'pi pi-hashtag',
                            //     routerLink: ['/parametres/type-documents'],
                            //     authorities: [],
                            // },
                            // {
                            //     label: 'Chauffeurs',
                            //     icon: 'pi pi-hashtag',
                            //     routerLink: ['/parametres/type-documents'],
                            //     authorities: [],
                            // },
                            // {
                            //     label: 'Compagnie',
                            //     icon: 'pi pi-hashtag',
                            //     routerLink: ['/parametres/type-documents'],
                            //     authorities: [],
                            // },
                            // {
                            //     label: 'Type de dépense',
                            //     icon: 'pi pi-hashtag',
                            //     routerLink: ['/parametres/type-documents'],
                            //     authorities: [],
                            // },
                            // {
                            //     label: 'Mails',
                            //     icon: 'pi pi-hashtag',
                            //     routerLink: ['/parametres/type-documents'],
                            //     authorities: [],
                            // },
                        ],
                    },
                    {
                        label: 'Utilisateurs',
                        icon: 'pi pi-users',
                        authorities: ['ROLE_UTILISATEURS'],
                        items: [
                            {
                                label: 'Profils',
                                icon: 'pi pi-flag',
                                routerLink: ['/users/profils'],
                                authorities: [],
                            },
                            {
                                label: 'Utilisateurs',
                                icon: 'pi pi-users',
                                routerLink: ['/users'],
                                authorities: [],
                            },
                        ],
                    },
                ]
            },

            // {
            //     label: 'Apps',
            //     icon: 'pi pi-th-large',
            //     items: [
            //         {
            //             label: 'Blog',
            //             icon: 'pi pi-fw pi-comment',
            //             items: [
            //                 {
            //                     label: 'List',
            //                     icon: 'pi pi-fw pi-image',
            //                     routerLink: ['/apps/blog/list']
            //                 },
            //                 {
            //                     label: 'Detail',
            //                     icon: 'pi pi-fw pi-list',
            //                     routerLink: ['/apps/blog/detail']
            //                 },
            //                 {
            //                     label: 'Edit',
            //                     icon: 'pi pi-fw pi-pencil',
            //                     routerLink: ['/apps/blog/edit']
            //                 }
            //             ]
            //         },
            //         {
            //             label: 'Calendar',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/apps/calendar']
            //         },
            //         {
            //             label: 'Chat',
            //             icon: 'pi pi-fw pi-comments',
            //             routerLink: ['/apps/chat']
            //         },
            //         {
            //             label: 'Files',
            //             icon: 'pi pi-fw pi-folder',
            //             routerLink: ['/apps/files']
            //         },
            //         {
            //             label: 'Kanban',
            //             icon: 'pi pi-fw pi-sliders-v',
            //             routerLink: ['/apps/kanban']
            //         },
            //         {
            //             label: 'Mail',
            //             icon: 'pi pi-fw pi-envelope',
            //             items: [
            //                 {
            //                     label: 'Inbox',
            //                     icon: 'pi pi-fw pi-inbox',
            //                     routerLink: ['/apps/mail/inbox']
            //                 },
            //                 {
            //                     label: 'Compose',
            //                     icon: 'pi pi-fw pi-pencil',
            //                     routerLink: ['/apps/mail/compose']
            //                 },
            //                 {
            //                     label: 'Detail',
            //                     icon: 'pi pi-fw pi-comment',
            //                     routerLink: ['/apps/mail/detail/1000']
            //                 }
            //             ]
            //         },
            //         {
            //             label: 'Task List',
            //             icon: 'pi pi-fw pi-check-square',
            //             routerLink: ['/apps/tasklist']
            //         }
            //     ]
            // },
            // {
            //     label: 'UI Kit',
            //     icon: 'pi pi-fw pi-star-fill',
            //     items: [
            //         {
            //             label: 'Form Layout',
            //             icon: 'pi pi-fw pi-id-card',
            //             routerLink: ['/uikit/formlayout']
            //         },
            //         {
            //             label: 'Input',
            //             icon: 'pi pi-fw pi-check-square',
            //             routerLink: ['/uikit/input']
            //         },
            //         {
            //             label: 'Float Label',
            //             icon: 'pi pi-fw pi-bookmark',
            //             routerLink: ['/uikit/floatlabel']
            //         },
            //         {
            //             label: 'Invalid State',
            //             icon: 'pi pi-fw pi-exclamation-circle',
            //             routerLink: ['/uikit/invalidstate']
            //         },
            //         {
            //             label: 'Button',
            //             icon: 'pi pi-fw pi-box',
            //             routerLink: ['/uikit/button']
            //         },
            //         {
            //             label: 'Table',
            //             icon: 'pi pi-fw pi-table',
            //             routerLink: ['/uikit/table']
            //         },
            //         {
            //             label: 'List',
            //             icon: 'pi pi-fw pi-list',
            //             routerLink: ['/uikit/list']
            //         },
            //         {
            //             label: 'Tree',
            //             icon: 'pi pi-fw pi-share-alt',
            //             routerLink: ['/uikit/tree']
            //         },
            //         {
            //             label: 'Panel',
            //             icon: 'pi pi-fw pi-tablet',
            //             routerLink: ['/uikit/panel']
            //         },
            //         {
            //             label: 'Overlay',
            //             icon: 'pi pi-fw pi-clone',
            //             routerLink: ['/uikit/overlay']
            //         },
            //         {
            //             label: 'Media',
            //             icon: 'pi pi-fw pi-image',
            //             routerLink: ['/uikit/media']
            //         },
            //         {
            //             label: 'Menu',
            //             icon: 'pi pi-fw pi-bars',
            //             routerLink: ['/uikit/menu'],
            //             routerLinkActiveOptions: {
            //                 paths: 'subset',
            //                 queryParams: 'ignored',
            //                 matrixParams: 'ignored',
            //                 fragment: 'ignored'
            //             }
            //         },
            //         {
            //             label: 'Message',
            //             icon: 'pi pi-fw pi-comment',
            //             routerLink: ['/uikit/message']
            //         },
            //         {
            //             label: 'File',
            //             icon: 'pi pi-fw pi-file',
            //             routerLink: ['/uikit/file']
            //         },
            //         {
            //             label: 'Chart',
            //             icon: 'pi pi-fw pi-chart-bar',
            //             routerLink: ['/uikit/charts']
            //         },
            //         {
            //             label: 'Misc',
            //             icon: 'pi pi-fw pi-circle-off',
            //             routerLink: ['/uikit/misc']
            //         }
            //     ]
            // },
            // {
            //     label: 'Prime Blocks',
            //     icon: 'pi pi-fw pi-prime',
            //     items: [
            //         {
            //             label: 'Free Blocks',
            //             icon: 'pi pi-fw pi-eye',
            //             routerLink: ['/blocks']
            //         },
            //         {
            //             label: 'All Blocks',
            //             icon: 'pi pi-fw pi-globe',
            //             url: ['https://www.primefaces.org/primeblocks-ng'],
            //             target: '_blank'
            //         }
            //     ]
            // },
            // {
            //     label: 'Utilities',
            //     icon: 'pi pi-fw pi-compass',
            //     items: [
            //         {
            //             label: 'PrimeIcons',
            //             icon: 'pi pi-fw pi-prime',
            //             routerLink: ['utilities/icons']
            //         },
            //         {
            //             label: 'Colors',
            //             icon: 'pi pi-fw pi-palette',
            //             routerLink: ['utilities/colors']
            //         },
            //         {
            //             label: 'PrimeFlex',
            //             icon: 'pi pi-fw pi-desktop',
            //             url: ['https://www.primefaces.org/primeflex/'],
            //             target: '_blank'
            //         },
            //         {
            //             label: 'Figma',
            //             icon: 'pi pi-fw pi-pencil',
            //             url: ['https://www.figma.com/file/zQOW0XBXdCTqODzEOqwBtt/Preview-%7C-Apollo-2022?node-id=335%3A21768&t=urYI89V3PLNAZEJG-1/'],
            //             target: '_blank'
            //         }
            //     ]
            // },
            // {
            //     label: 'Pages',
            //     icon: 'pi pi-fw pi-briefcase',
            //     items: [
            //         {
            //             label: 'Landing',
            //             icon: 'pi pi-fw pi-globe',
            //             routerLink: ['/landing']
            //         },
            //         {
            //             label: 'Auth',
            //             icon: 'pi pi-fw pi-user',
            //             items: [
            //                 {
            //                     label: 'Login',
            //                     icon: 'pi pi-fw pi-sign-in',
            //                     routerLink: ['/auth/login']
            //                 },
            //                 {
            //                     label: 'Error',
            //                     icon: 'pi pi-fw pi-times-circle',
            //                     routerLink: ['/auth/error']
            //                 },
            //                 {
            //                     label: 'Access Denied',
            //                     icon: 'pi pi-fw pi-lock',
            //                     routerLink: ['/auth/access']
            //                 },
            //                 {
            //                     label: 'Register',
            //                     icon: 'pi pi-fw pi-user-plus',
            //                     routerLink: ['/auth/register']
            //                 },
            //                 {
            //                     label: 'Forgot Password',
            //                     icon: 'pi pi-fw pi-question',
            //                     routerLink: ['/auth/forgotpassword']
            //                 },
            //                 {
            //                     label: 'New Password',
            //                     icon: 'pi pi-fw pi-cog',
            //                     routerLink: ['/auth/newpassword']
            //                 },
            //                 {
            //                     label: 'Verification',
            //                     icon: 'pi pi-fw pi-envelope',
            //                     routerLink: ['/auth/verification']
            //                 },
            //                 {
            //                     label: 'Lock Screen',
            //                     icon: 'pi pi-fw pi-eye-slash',
            //                     routerLink: ['/auth/lockscreen']
            //                 }
            //             ]
            //         },
            //         {
            //             label: 'Crud',
            //             icon: 'pi pi-fw pi-pencil',
            //             routerLink: ['/pages/crud']
            //         },
            //         {
            //             label: 'Timeline',
            //             icon: 'pi pi-fw pi-calendar',
            //             routerLink: ['/pages/timeline']
            //         },
            //         {
            //             label: 'Invoice',
            //             icon: 'pi pi-fw pi-dollar',
            //             routerLink: ['/pages/invoice']
            //         },
            //         {
            //             label: 'About Us',
            //             icon: 'pi pi-fw pi-user',
            //             routerLink: ['/pages/aboutus']
            //         },
            //         {
            //             label: 'Help',
            //             icon: 'pi pi-fw pi-question-circle',
            //             routerLink: ['/pages/help']
            //         },
            //         {
            //             label: 'Not Found',
            //             icon: 'pi pi-fw pi-exclamation-circle',
            //             routerLink: ['/pages/notfound']
            //         },
            //         {
            //             label: 'Empty',
            //             icon: 'pi pi-fw pi-circle-off',
            //             routerLink: ['/pages/empty']
            //         },
            //         {
            //             label: 'FAQ',
            //             icon: 'pi pi-fw pi-question',
            //             routerLink: ['/pages/faq']
            //         },
            //         {
            //             label: 'Contact Us',
            //             icon: 'pi pi-fw pi-phone',
            //             routerLink: ['/pages/contact']
            //         }
            //     ]
            // },
            // {
            //     label: 'E-Commerce',
            //     icon: 'pi pi-fw pi-wallet',
            //     items: [
            //         {
            //             label: 'Product Overview',
            //             icon: 'pi pi-fw pi-image',
            //             routerLink: ['ecommerce/product-overview']
            //         },
            //         {
            //             label: 'Product List',
            //             icon: 'pi pi-fw pi-list',
            //             routerLink: ['ecommerce/product-list']
            //         },
            //         {
            //             label: 'New Product',
            //             icon: 'pi pi-fw pi-plus',
            //             routerLink: ['ecommerce/new-product']
            //         },
            //         {
            //             label: 'Shopping Cart',
            //             icon: 'pi pi-fw pi-shopping-cart',
            //             routerLink: ['ecommerce/shopping-cart']
            //         },
            //         {
            //             label: 'Checkout Form',
            //             icon: 'pi pi-fw pi-check-square',
            //             routerLink: ['ecommerce/checkout-form']
            //         },
            //         {
            //             label: 'Order History',
            //             icon: 'pi pi-fw pi-history',
            //             routerLink: ['ecommerce/order-history']
            //         },
            //         {
            //             label: 'Order Summary',
            //             icon: 'pi pi-fw pi-file',
            //             routerLink: ['ecommerce/order-summary']
            //         }
            //     ]
            // },
            // {
            //     label: 'User Management',
            //     icon: 'pi pi-fw pi-user',
            //     items: [
            //         {
            //             label: 'List',
            //             icon: 'pi pi-fw pi-list',
            //             routerLink: ['profile/list']
            //         },
            //         {
            //             label: 'Create',
            //             icon: 'pi pi-fw pi-plus',
            //             routerLink: ['profile/create']
            //         }
            //     ]
            // },
            // {
            //     label: 'Hierarchy',
            //     icon: 'pi pi-fw pi-align-left',
            //     items: [
            //         {
            //             label: 'Submenu 1',
            //             icon: 'pi pi-fw pi-align-left',
            //             items: [
            //                 {
            //                     label: 'Submenu 1.1',
            //                     icon: 'pi pi-fw pi-align-left',
            //                     items: [
            //                         {
            //                             label: 'Submenu 1.1.1',
            //                             icon: 'pi pi-fw pi-align-left',
            //                         },
            //                         {
            //                             label: 'Submenu 1.1.2',
            //                             icon: 'pi pi-fw pi-align-left',
            //                         },
            //                         {
            //                             label: 'Submenu 1.1.3',
            //                             icon: 'pi pi-fw pi-align-left',
            //                         }
            //                     ]
            //                 },
            //                 {
            //                     label: 'Submenu 1.2',
            //                     icon: 'pi pi-fw pi-align-left',
            //                     items: [
            //                         {
            //                             label: 'Submenu 1.2.1',
            //                             icon: 'pi pi-fw pi-align-left',
            //                         }
            //                     ]
            //                 }
            //             ]
            //         },
            //         {
            //             label: 'Submenu 2',
            //             icon: 'pi pi-fw pi-align-left',
            //             items: [
            //                 {
            //                     label: 'Submenu 2.1',
            //                     icon: 'pi pi-fw pi-align-left',
            //                     items: [
            //                         {
            //                             label: 'Submenu 2.1.1',
            //                             icon: 'pi pi-fw pi-align-left',
            //                         },
            //                         {
            //                             label: 'Submenu 2.1.2',
            //                             icon: 'pi pi-fw pi-align-left',
            //                         }
            //                     ]
            //                 },
            //                 {
            //                     label: 'Submenu 2.2',
            //                     icon: 'pi pi-fw pi-align-left',
            //                     items: [
            //                         {
            //                             label: 'Submenu 2.2.1',
            //                             icon: 'pi pi-fw pi-align-left',
            //                         }
            //                     ]
            //                 }
            //             ]
            //         }
            //     ]
            // },
            // {
            //     label: 'Start',
            //     icon: 'pi pi-fw pi-download',
            //     items: [
            //         {
            //             label: 'Buy Now',
            //             icon: 'pi pi-fw pi-shopping-cart',
            //             url: ['https://www.primefaces.org/store']
            //         },
            //         {
            //             label: 'Documentation',
            //             icon: 'pi pi-fw pi-info-circle',
            //             routerLink: ['/documentation']
            //         }
            //     ]
            // }
        ];
    }
}
