import {TypeDocument} from "./type-document.model";
import {Hotel} from "./hotel.model";
import {Document} from "./document.model";
import {File} from "../api/file";
import {Marchandise} from "./marchandise.model";

export class LignesMarchandise {
    constructor(
        public id?: number,
        public quantite?: number,
        public prixUnitaire?: number,
        public montantHT?: number,
        public dateLivraison?: Date,
        public periodeLivraison?: string,
        public marchandise?: Marchandise,
    ) {
    }
}
