import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Marchandise} from "../../../models/marchandise.model";

@Injectable({
    providedIn: 'root'
})
export class MarchandiseService {
    readonly API_URL = environment.API_URL;
    marchandises$: BehaviorSubject<Marchandise[]> = new BehaviorSubject<Marchandise[]>([]);

    constructor(
        private http: HttpClient,
    ) {
        this.initMarchandise();
    }

    public initMarchandise(): void {
        this.getAllMarchandise().subscribe({
            next: response => {
                if (response.body !== null) {
                    this.marchandises$.next(response.body);
                }
            }
        });


    }

    public getAllMarchandise(): Observable<HttpResponse<Marchandise[]>> {
        return this.http.get<Marchandise[]>(`${this.API_URL}/api/marchandises`, { observe: "response"});
    }


    create(p: Marchandise): Observable<HttpResponse<Marchandise>> {
        return this.http.post<Marchandise>(`${this.API_URL}/api/marchandises`, p, { observe: 'response'});
    }

    update(p: Marchandise): Observable<HttpResponse<Marchandise>> {
        return this.http.put<Marchandise>(`${this.API_URL}/api/marchandises/${p.id}`, p, { observe: 'response'});
    }

    delete(p: Marchandise): Observable<HttpResponse<void>> {
        return this.http.delete<void>(`${this.API_URL}/api/marchandises/${p.id}`, { observe: 'response'});
    }
}
