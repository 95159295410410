import {FactureProforma} from "./facture-proforma.model";
import {TypeDeDepense} from "./type-de-depense.model";
import {Devise} from "./devise.model";

export class Depense {
    constructor(
        public id?: number,
        public date?: string,
        public montant?: number,
        public factureProforma?: FactureProforma,
        public factureDefinitive?: any, // Le type est à revoir
        public typeDepense?: TypeDeDepense,
        public devise?: Devise,
        public createdBy?: string,
        public createdDate?: string,
    ) {
    }
}
