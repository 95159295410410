import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from "../../features/components/auth/auth.service";

@Directive({
  selector: '[appHasAnyAuthority]'
})
export class HasAnyAuthorityDirective {
    private authorities: string[] = [];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private authService: AuthService,
    ) { }

    @Input()
    set appHasAnyAuthority(value: string | string[]) {
        this.authorities = typeof value === 'string' ? [value] : value;
        this.updateView();
    }

    private updateView(): void {
        const hasAnyAuthority = this.authService.hasAnyAuthority(this.authorities);
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        // if (hasAnyAuthority) {
        //     this.viewContainerRef.createEmbeddedView(this.templateRef);
        // }
    }

}
