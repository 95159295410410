import {Fonction} from "./fonction.model";
import {Profil} from "./profil.model";
import {Hotel} from "./hotel.model";
import {UserAdmin} from "./user-admin.model";

export class User {
    constructor(
        public id?: number,
        public nom?: string,
        public prenom?: string,
        public telephone?: string,
        public email?: string,
        public sexe?: string,
        public activated?: boolean,
        public typeCompte?: string,
        public createdDate?: string,
        public createdBy?: string,
        public profil?: Profil,
        public defaultPassword?: boolean,
        public adminUser?: UserAdmin
    ) {
    }
}
