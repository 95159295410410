<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-profile-sidebar w-full sm:w-25rem">
    <div class="flex flex-column mx-auto md:mx-0">
        <span class="mb-2 font-semibold">Bienvenue</span>
        <span class="text-color-secondary font-medium mb-5">{{ currentUser.prenom + ' ' + currentUser.nom }}</span>

        <ul class="list-none m-0 p-0">
            <li>
                <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-user text-xl text-primary"></i>
                    </span>
                    <div class="ml-3">
                        <span class="mb-2 font-semibold">Mon Profil</span>
                        <p class="text-color-secondary m-0">Consulter les informations</p>
                    </div>
                </a>
            </li>
            <li>
                <a (click)="onLogout()" class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-power-off text-xl text-danger"></i>
                    </span>
                    <div class="ml-3">
                        <span class="mb-2 font-semibold text-danger">Se deconnecter</span>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</p-sidebar>
