import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import {UserRouteAccessGuard} from "./core/guards/user-route-access.guard";

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: '', component: AppLayoutComponent,
        children: [
            { path: '', loadChildren: () => import('./features/components/dashboards/dashboards.module').then(m => m.DashboardsModule) },
            { path: 'uikit', data: { breadcrumb: 'UI Kit' }, loadChildren: () => import('./features/components/uikit/uikit.module').then(m => m.UIkitModule) },
            { path: 'utilities', data: { breadcrumb: 'Utilities' }, loadChildren: () => import('./features/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
            { path: 'pages', data: { breadcrumb: 'Pages' }, loadChildren: () => import('./features/components/pages/pages.module').then(m => m.PagesModule) },
            { path: 'profile', data: { breadcrumb: 'User Management' }, loadChildren: () => import('./features/components/profile/profile.module').then(m => m.ProfileModule) },
            { path: 'documentation', data: { breadcrumb: 'Documentation' }, loadChildren: () => import('./features/components/documentation/documentation.module').then(m => m.DocumentationModule) },
            { path: 'blocks', data: { breadcrumb: 'Prime Blocks' }, loadChildren: () => import('./features/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
            { path: 'ecommerce', data: { breadcrumb: 'E-Commerce' }, loadChildren: () => import('./features/components/ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
            { path: 'apps', data: { breadcrumb: 'Apps' }, loadChildren: () => import('./features/components/apps/apps.module').then(m => m.AppsModule) },
            {
                path: 'pays',
                data: { breadcrumb: 'Pays'},
                loadChildren: () => import('./features/components/pays/pays.module').then( m => m.PaysModule),
            },
            { path: 'parametres', data: { breadcrumb: 'Paramètres' }, loadChildren: () => import('./features/components/parametres/parametres.module').then(m => m.ParametresModule) },
            {
                path: 'users',
                data: { breadcrumb: 'Utilisateurs'},
                loadChildren: () => import('./features/components/users/users.module').then( m => m.UsersModule ),
            },

            {
                path: 'clients',
                data: { breadcrumb: 'Clients' },
                loadChildren: () => import('./features/components/clients/clients.module').then( m => m.ClientsModule),
            },
            {
                path: 'fournisseurs',
                data: { breadcrumb: 'Fournisseurs' },
                loadChildren: () => import('./features/components/fournisseur/fournisseur.module').then( m => m.FournisseurModule),
            },
            {
                path: 'facture-commerciale',
                data: { breadcrumb: 'Facture commerciale' },
                loadChildren: () => import('./features/components/facture-commerciale/facture-commerciale.module').then( m => m.FactureCommercialeModule),
            },
            {
                path: 'statistiques',
                data: { breadcrumb: 'Statistiques' },
                loadChildren: () => import('./features/components/statistiques/statistiques.module').then( m => m.StatistiquesModule),
            },
            {
                path: 'cotations',
                data: { breadcrumb: 'Cotations' },
                loadChildren: () => import('./features/components/cotations/cotations.module').then( m => m.CotationsModule),
            },
            {
                path: 'marchandises',
                data: { breadcrumb: 'Marchandies' },
                loadChildren: () => import('./features/components/parametres/parametres.module').then( m => m.ParametresModule),
            },
          {
            path: 'bon-commandes',
            data: { breadcrumb: 'PO' },
            loadChildren: () => import('./features/components/bon-de-commande/bon-de-commande.module').then( m => m.BonDeCommandeModule),
          },
          {
            path: 'factures-definitives',
            data: { breadcrumb: 'Factures' },
            loadChildren: () => import('./features/components/facture-definitives/facture-definitives.module').then( m => m.FactureDefinitivesModule),
          },
            {
                path: 'caisses',
                data: { breadcrumb: 'Caisses' },
                loadChildren: () => import('./features/components/caisses/caisses.module').then( m => m.CaissesModule ),
            },
            {
                path: 'avances',
                data: { breadcrumb: 'Avances' },
                loadChildren: () => import('./features/components/avances/avances.module').then( m => m.AvancesModule ),
            },
          {
            path: 'approvisionnements',
            data: { breadcrumb: 'Approvisionnements' },
            loadChildren: () => import('./features/components/approvisionnement/approvisionnement.module').then( m => m.ApprovisionnementModule ),
          },
          {
            path: 'cotations/cotations-fournisseurs',
            data: { breadcrumb: 'Facture proforma fournisseur' },
            loadChildren: () => import('./features/components/facture-proforma-fournisseur/facture-proforma-fournisseur.module').then( m => m.FactureProformaFournisseurModule ),
          }

        ],
        canActivate: [UserRouteAccessGuard],
    },
    { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./features/components/auth/auth.module').then(m => m.AuthModule) },
    { path: 'landing', loadChildren: () => import('./features/components/landing/landing.module').then(m => m.LandingModule) },
    { path: 'notfound', loadChildren: () => import('./features/components/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
