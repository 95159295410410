import { Injectable } from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {TypeDeDepense} from "../../../models/type-de-depense.model";

@Injectable({
  providedIn: 'root'
})
export class TypeDeDepenseService {
  readonly API_URL = `${environment.API_URL}/api/type-depenses`;
  typeDeDepenses$: BehaviorSubject<TypeDeDepense[]> = new BehaviorSubject<TypeDeDepense[]>([]);

  constructor(
      private http: HttpClient,
  ) {
    this.initTypeDeDepense();
  }

  public initTypeDeDepense(): void {
    this.getAllTypeDeDepenses().subscribe({
      next: response => {
        if (response.body !== null) {
          this.typeDeDepenses$.next(response.body);
        }
      }
    });


  }

  public getAllTypeDeDepenses(): Observable<HttpResponse<TypeDeDepense[]>> {
    return this.http.get<TypeDeDepense[]>(`${this.API_URL}`, { observe: "response"});
  }


  create(p: TypeDeDepense): Observable<HttpResponse<TypeDeDepense>> {
    return this.http.post<TypeDeDepense>(`${this.API_URL}`, p, { observe: 'response'});
  }

  update(p: TypeDeDepense): Observable<HttpResponse<TypeDeDepense>> {
    return this.http.put<TypeDeDepense>(`${this.API_URL}/${p.id}`, p, { observe: 'response'});
  }

  delete(p: TypeDeDepense): Observable<HttpResponse<void>> {
    return this.http.delete<void>(`${this.API_URL}/${p.id}`, { observe: 'response'});
  }
}
