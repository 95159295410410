<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <a [routerLink]="['/']" class="app-logo">
        <img src="assets/layout/images/logo-ecomaf.jpg" class="w-16rem" alt="Logo">

        <button class="layout-sidebar-anchor p-link" type="button" (click)="anchor()"></button>

    </a>

    <div class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>
