import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deviseFormatter'
})
export class DeviseFormatterPipe implements PipeTransform {

    transform(value: any) {
        if (value === null || value === undefined) {
            return '0';
        }

        let formated = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'XOF', minimumFractionDigits: 0 }).format(+value);
        for (let i = 0; i < formated.length; i++) {
            if (formated[i] === '.') {
                formated = formated.replace('.', ' ');
            }
        }
        formated = formated.replace('CFA', '').replace('XOF', '').replace('F', '');
        return formated;
    }

}
