import {Component, OnInit} from '@angular/core';
import {Marchandise} from "../../../features/models/marchandise.model";
import {LignesMarchandise} from "../../../features/models/lignesMarchandise.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {MarchandiseService} from "../../../features/components/parametres/marchandise/marchandise.service";
import {Observable} from "rxjs";
import {
  CreateUpdateMarchandiseComponent
} from "../../../features/components/parametres/marchandise/create-update-marchandise/create-update-marchandise.component";

@Component({
  selector: 'app-add-or-update-ligne-marchandise',
  templateUrl: './add-or-update-ligne-marchandise.component.html',
  styleUrls: ['./add-or-update-ligne-marchandise.component.scss']
})
export class AddOrUpdateLigneMarchandiseComponent implements OnInit {
  marchandises$: Observable<Marchandise[]> = new Observable<Marchandise[]>();
  ligneMarchandise!: LignesMarchandise;
  formLigneMarchandise!: FormGroup;


  constructor(
      private fb: FormBuilder,
      private dynamicDialogConfig: DynamicDialogConfig,
      private dialogRef: DynamicDialogRef,
      private marchandiseService: MarchandiseService,
      private dialogService: DialogService,
  ) {
  }

  ngOnInit(): void {
    this.marchandises$ = this.marchandiseService.marchandises$;
    this.ligneMarchandise = this.dynamicDialogConfig.data !== undefined ? this.dynamicDialogConfig.data : new LignesMarchandise();
    this.createFormGroup();
  }

  createFormGroup(): void {
    this.formLigneMarchandise = this.fb.group({
      marchandise: [this.ligneMarchandise.marchandise, Validators.required],
      code: [null],
      prixUnitaire: [this.ligneMarchandise.prixUnitaire, Validators.required],
      quantite: [this.ligneMarchandise.quantite, Validators.required],
    });
  }

  onGetLigneMarchandiseInfos() {
    this.ligneMarchandise.marchandise = this.formLigneMarchandise.value.marchandise;
    this.ligneMarchandise.marchandise!.code! = this.formLigneMarchandise.value.code;
    this.ligneMarchandise.prixUnitaire = Number(this.formLigneMarchandise.value.prixUnitaire);
    this.ligneMarchandise.quantite = Number(this.formLigneMarchandise.value.quantite);
    this.ligneMarchandise.montantHT = this.ligneMarchandise.quantite * this.ligneMarchandise.prixUnitaire;
    this.onCloseModal(true);
  }

  onCloseModal(withValue: boolean) {
    this.dialogRef.close(withValue ? this.ligneMarchandise : null);
  }

  onGetSelectedMarchandise(event: any) {
    const selectedMarchandise = event.value as Marchandise;
    this.formLigneMarchandise.patchValue({
      code: selectedMarchandise.code,
    });
  }

  onAddNewMarchandise() {
    const dialogRef: DynamicDialogRef = this.dialogService.open(CreateUpdateMarchandiseComponent, {
      header: `Créer une nouvelle marchandise`,
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
    });

    dialogRef.onClose.subscribe({
      next: (response: Marchandise) => {
        if (response !== null && response !== undefined) {
          this.marchandiseService.initMarchandise();
          this.marchandises$ = this.marchandiseService.marchandises$;
          this.formLigneMarchandise.patchValue({
            marchandise: response,
            code: response.code,
          });
        }
      }
    });
  }

  onClearSelectedMarchandise() {
    this.formLigneMarchandise.patchValue({
      marchandise: null,
      code: null,
    });
  }
}
