<form autocomplete="off" novalidate #form="ngForm">
  <div class="grid formgrid">
    <div class="col-12 md:col-12 mb-3 field p-fluid">
      <label for="motif" class="text-900 font-semibold">Motif de la suppression</label>
      <textarea required id="motif" name="motif" type="text" pInputTextarea [rows]="5" [(ngModel)]="motif"></textarea>
    </div>
  </div>
</form>

<div class="text-right mt-3">
  <button (click)="onDismiss()" pButton pRipple type="button" label="Annuler" class="p-button-outlined p-button-danger mr-3"></button>
  <button (click)="deleteFacture()" [disabled]="form.invalid" pButton pRipple type="button" label="Confoirmer la suppression" class="p-button-outlined p-button-success"></button>
</div>
