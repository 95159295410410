<form [formGroup]="formLigneMarchandise" (ngSubmit)="onGetLigneMarchandiseInfos()">
    <div class="formgrid">
        <div class="field p-fluid">
            <label>Marchandise <span class="text-danger">*</span></label>

            <div class="grid">
                <div class="col-12 md:col-11 field p-fluid">
                    <p-dropdown *ngIf="marchandises$ | async as marchandises" [options]="marchandises"
                                [style]="{'width':'100%'}"
                                name="marchandise"
                                formControlName="marchandise"
                                placeholder="Sélectionner la marchandise"
                                emptyMessage="Aucune marchandise trouvée"
                                optionLabel="label"
                                dataKey="id"
                                appendTo="body"
                                class="mb-3"
                                [filter]="true"
                                (onClear)="onClearSelectedMarchandise()"
                                (onChange)="onGetSelectedMarchandise($event)"
                                [showClear]="true">
                    </p-dropdown>
                </div>

                <div class="col-12 md:col-1 field p-fluid">
                    <button pTooltip="Ajouter une nouvelle marchandise"
                            (click)="onAddNewMarchandise()"
                            tooltipPosition="top"
                            pButton pRipple
                            type="button" icon="pi pi-plus"
                            class="p-button-outlined p-button-success">
                    </button>
                </div>
            </div>
        </div>

        <div class="field p-fluid">
            <label>Code de la marchandise</label>
            <input name="code" formControlName="code" type="text" placeholder="Saisir le code" pInputText>
        </div>

        <div class="field p-fluid">
            <label>Quantité <span class="text-danger">*</span></label>
            <input name="quantite" formControlName="quantite" type="text" mask="separator.0" thousandSeparator=" " pInputText>
        </div>

        <div class="field p-fluid">
            <label>Prix unitaire <span class="text-danger">*</span></label>
            <input name="prixUnitaire" formControlName="prixUnitaire" type="text" mask="separator.0" thousandSeparator=" " pInputText>
        </div>

        <div class="text-right mt-3">
            <button pButton pRipple type="reset" (click)="onCloseModal(false)" label="Annuler" class="p-button-outlined p-button-danger mr-3"></button>
            <button [disabled]="formLigneMarchandise.invalid" pButton pRipple type="submit" label="Enregistrer" class="p-button-outlined p-button-success"></button>
        </div>

    </div>
</form>
