import {Component, Input, OnInit} from '@angular/core';
import { LayoutService } from '../service/app.layout.service';
import {AuthService} from "../../features/components/auth/auth.service";
import {User} from "../../features/models/user.model";

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent implements OnInit {
    @Input()
    currentUser: User = new User();
    constructor(
        public layoutService: LayoutService,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
    }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    onLogout() {
        this.visible = false;
        this.authService.onLogout();
    }
}
