import {Fonction} from "./fonction.model";
import {Profil} from "./profil.model";
import {Hotel} from "./hotel.model";

export class User {
    constructor(
        public id?: number,
        public nom?: string,
        public prenom?: string,
        public telephone?: string,
        public email?: string,
        public genre?: string,
        public activated?: boolean,
        public typeUtilisateur?: string,
        public createdDate?: string,
        public createdBy?: string,
        public fonction?: Fonction,
        public profil?: Profil,
        public hotel?: Hotel,
    ) {
    }
}
