import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "../../features/components/auth/auth.service";

@Injectable({
    providedIn: 'root'
})
export class UserRouteAccessGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router,
    ) {
    }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.authService.isTokenExpired() && !this.authService.getLocalUser()?.defaultPassword) {
            return true;
        }
        this.router.navigate(['/auth/login']);
        return true;
    }

}
