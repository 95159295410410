import { Component } from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";
import {ClientService} from "../../../features/components/clients/client.service";
import {AvanceService} from "../../../features/components/avances/avance.service";
import {Avance} from "../../../features/models/avance.model";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  motif?: string;
  avance: Avance = new Avance();

  constructor(
    private dialogRef: DynamicDialogRef,
    private dynamicDialog:  DynamicDialogConfig,
    private messageService: MessageService,
    private dialogService: DialogService,
    private clientService: ClientService,
    private avanceService: AvanceService
  ) {
  }


  ngOnInit(): void {
    if (this.dynamicDialog.data) {
      this.avance = this.dynamicDialog.data;
    }
  }

  onDismiss() {
     this.dialogRef.close(false)
  }

  onSaveAvance() {

  }

  deleteFacture(): void {
    this.avanceService.delete(this.avance,this.motif!).subscribe({
      next: () => {
        this.messageService.add({ severity: 'success', detail: `L'avance de : '${this.avance.typeAvance}' a été supprimée avec succès !` });
        this.dialogRef.close(true);
      },
      error: err => {
        const message = err?.error?.detail ? err?.error?.detail : `Une erreur est survenue lors de la suppression de l'avance !`;
        this.messageService.add({ severity: 'error', detail: message });
      }
    });
  }
}
