import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {ClientCriteria} from "../../models/criteria/client-criteria.model";
import {Client} from "../../models/client.model";

@Injectable({
  providedIn: 'root'
})
export class ClientService {
    readonly API_URL = `${environment.API_URL}/api/clients`;
    clients$: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>([]);

    constructor(
        private http: HttpClient,
    ) {
        this.initListClients();
    }

    initListClients(): void {
        this.findAll().subscribe({
            next: response => {
                this.clients$.next(response.body  as Client[]);
            }
        });
    }

    findById(clientId: number): Observable<HttpResponse<Client>> {
        return this.http.get<Client>(`${this.API_URL}/${clientId}`, { observe: "response"});
    }

    findAll(): Observable<HttpResponse<Client[]>> {
        return this.http.get<Client[]>(`${this.API_URL}`, { observe: "response"});
    }

    create(client: Client, files: File[]): Observable<HttpResponse<Client>> {
        const clientFormData: FormData = new FormData();
        clientFormData.append('clientDTO', new Blob([JSON.stringify(client)], { type: 'application/json' }));
        files.forEach(file => clientFormData.append('files', file, file.name));
        return this.http.post<Client>(`${this.API_URL}`, clientFormData, { observe: "response"});
    }

    update(client: Client): Observable<HttpResponse<Client>> {
        return this.http.put<Client>(`${this.API_URL}/${client.id}`, client, { observe: "response"});
    }

    delete(clientId: number): Observable<HttpResponse<void>> {
        return this.http.delete<void>(`${this.API_URL}/${clientId}`, { observe: "response"});
    }

    findByCriteria(keyword: string, page: number, size: number): Observable<HttpResponse<Client[]>> {
        const params = this.buildPagination(page, size);
        return this.http.get<Client[]>(`${this.API_URL}/criteria?keyword=${keyword}`, { params: params, observe: "response"});
    }

    private buildPagination(page: number, size: number): HttpParams {
        let params: HttpParams = new HttpParams();
        // Set page and size
        params = page ? params.set('page', page) : params;
        params = size ? params.set('size', size) : params;
        return params;
    }

    private buildClientCriteriaParams(criteria: ClientCriteria, page: number, size: number): HttpParams {
        let params: HttpParams = new HttpParams();
        // Set page and size
        params = page ? params.set('page', page) : params;
        params = size ? params.set('size', size) : params;

        // Set criteria values
        params = criteria.hotelId ? params.set('hotelId.equals', criteria.hotelId) : params;
        params = criteria.nom ? params.set('nom.contains', criteria.nom) : params;
        params = criteria.prenom ? params.set('prenom.contains', criteria.prenom) : params;
        params = criteria.telephone ? params.set('telephone.contains', criteria.telephone) : params;
        params = criteria.email ? params.set('email.contains', criteria.email) : params;
        params = criteria.referenceDocumentIdentite ? params.set('referenceDocumentIdentite.contains', criteria.referenceDocumentIdentite) : params;
        params = criteria.profession ? params.set('profession.contains', criteria.profession) : params;

        return params;
    }

  getClients(): Observable<HttpResponse<Client[]>> {
    return this.http.get<Client[]>(`${this.API_URL}`, { observe: "response"});
  }
}
