import { Injectable } from '@angular/core';
import {HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Avance} from "../../models/avance.model";
import {AvanceFactureCommun} from "../../models/AvanceFactureCommun.model";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AvanceService {
    readonly API_URL = `${environment.API_URL}/api/avances`;
  readonly API_URL_GENERATION = `${environment.API_URL}/api/generation/avances`;


  constructor(
        private http: HttpClient,
    ) { }

    findById(bonID: number): Observable<HttpResponse<Avance>> {
        return this.http.get<Avance>(`${this.API_URL}/${bonID}`, { observe: "response"});
    }

  findAll(): Observable<HttpResponse<Avance[]>> {
    return this.http.get<Avance[]>(`${this.API_URL}`, { observe: "response"});
  }

  create(avance: Avance): Observable<HttpResponse<Avance>> {
    return this.http.post<Avance>(`${this.API_URL}`, avance, { observe: 'response'});
  }

  update(avance: Avance): Observable<HttpResponse<Avance>> {
    return this.http.put<Avance>(`${this.API_URL}/${avance.id}`, avance, { observe: 'response'});
  }

  delete(avance: Avance, motif:string): Observable<HttpResponse<void>> {
    return this.http.delete<void>(`${this.API_URL}/${avance.id}?motif=${motif}`, { observe: 'response'});
  }


  public findByCriteria(page: number, size: number,criteria: Avance): Observable<HttpResponse<Avance[]>> {
    let params: HttpParams = new HttpParams();
    params = page ? params.set('page', page) : params;
    params = size ? params.set('size', size) : params;
    return this.http.post<Avance[]>(`${this.API_URL}/criteria`, criteria, { params: params,observe: "response"});
  }

  getBonCommandes(): Observable<HttpResponse<Avance[]>> {
    return this.http.get<Avance[]>(`${this.API_URL}`, { observe: "response"});
  }

  saveWithFile(avance: Avance, files: File[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('avanceDTO', new Blob([JSON.stringify(avance)], { type: 'application/json' }));
    files.forEach(file => {
      formData.append('files', file, file.name);
    });

    return this.http.post(`${this.API_URL}`, formData);
  }

  getFactureInfos(clientId: number, entityName: string): Observable<HttpResponse<AvanceFactureCommun[]>> {
    let params = new HttpParams()
      .set('clientId', clientId.toString())
      .set('entityName', entityName);

    return this.http.get<AvanceFactureCommun[]>(`${this.API_URL}/infos-facture`, { params, observe: 'response' })
      .pipe(
        map((response: HttpResponse<AvanceFactureCommun[]>) => {
          if (response.body) {
            response.body.forEach(facture => {
              facture.libelleDisplay = `${facture.numero} | ${facture.libelle}`;
            });
          }
          return response;
        })
      );
  }

  getTypeAvance(): Observable<HttpResponse<String[]>> {
    return this.http.get<String[]>(`${this.API_URL}/type-avances`, { observe: "response"});
  }

  public findByEntityIdAndEntityName(entityId: number, entityName: string): Observable<HttpResponse<Avance[]>> {
    return this.http.get<Avance[]>(`${this.API_URL}/by-entity?entityId=${entityId}&entityName=${entityName}`, { observe: "response"});
  }

  generaterRecuAvance(avanceId: number) {
    return this.http.get(`${this.API_URL_GENERATION}/${avanceId}`, {observe: 'body', responseType: 'arraybuffer'});
  }
}
