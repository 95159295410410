<form [formGroup]="formLigneMarchandise" (ngSubmit)="onGetLigneMarchandiseInfos()">
    <div class="formgrid">
        <div class="field p-fluid">
            <label>Marchandise <span class="text-danger">*</span></label>
            <p-dropdown *ngIf="marchandises$ | async as marchandises" [options]="marchandises"
                        [style]="{'width':'100%'}"
                        name="marchandise"
                        formControlName="marchandise"
                        placeholder="Sélectionner la marchandise"
                        emptyMessage="Aucune marchandise trouvée"
                        optionLabel="libelle"
                        dataKey="id"
                        appendTo="body"
                        class="mb-3"
                        [showClear]="true">
            </p-dropdown>
        </div>

        <div class="field p-fluid">
            <label>Quantité <span class="text-danger">*</span></label>
            <input name="quantite" formControlName="quantite" type="text" mask="separator.0" thousandSeparator=" " pInputText>
        </div>

        <div class="field p-fluid">
            <label>Prix unitaire <span class="text-danger">*</span></label>
            <input name="prixUnitaire" formControlName="prixUnitaire" type="text" mask="separator.0" thousandSeparator=" " pInputText>
        </div>

        <div class="text-right mt-3">
            <button pButton pRipple type="reset" (click)="onCloseModal(false)" label="Annuler" class="p-button-outlined p-button-danger mr-3"></button>
            <button [disabled]="formLigneMarchandise.invalid" pButton pRipple type="submit" label="Enregistrer" class="p-button-outlined p-button-success"></button>
        </div>

    </div>
</form>
