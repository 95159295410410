import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
    pdfUrl!: string;

    constructor(
        private dynamicDialogConfig: DynamicDialogConfig,
        private http: HttpClient
    ) {
    }

    ngOnInit(): void {
        this.pdfUrl = this.dynamicDialogConfig.data as string;
    }

}
