import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import {AuthService} from "../features/components/auth/auth.service";
import {User} from "../features/models/user.model";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent implements OnInit {

    @ViewChild('menubutton') menuButton!: ElementRef;
    currentUser: User = new User();

    constructor(
        public layoutService: LayoutService,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.currentUser = this.authService.getLocalUser()!;
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }

}
