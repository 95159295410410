import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Marchandise} from "../../../../models/marchandise.model";
import {MessageService} from "primeng/api";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {MarchandiseService} from "../marchandise.service";

@Component({
  selector: 'app-create-update-marchandise',
  templateUrl: './create-update-marchandise.component.html',
  styleUrls: ['./create-update-marchandise.component.scss']
})
export class CreateUpdateMarchandiseComponent implements OnInit {
  formMarchandise!: FormGroup;
  marchandise!: Marchandise;

  constructor(
      private marchandiseService: MarchandiseService,
      private messageService: MessageService,
      private fb: FormBuilder,
      private dialogRef: DynamicDialogRef,
      private dynamicDialogConfig: DynamicDialogConfig,
  ) {
  }

  ngOnInit() {
    if (this.dynamicDialogConfig.data !== undefined) {
      this.marchandise = this.dynamicDialogConfig.data;
    } else {
      this.marchandise = new Marchandise();
    }
    this.createFormGroup();
  }

  createFormGroup(): void {
    this.formMarchandise = this.fb.group({
      code: [this.marchandise?.code],
      libelle: [this.marchandise?.libelle, Validators.required],
      prixAchat: [this.marchandise?.prixAchat],
      prixVente: [this.marchandise?.prixVente],
    });
  }

  onGetMarchandiseInfos() {
    this.marchandise.code =  this.formMarchandise.value.code;
    this.marchandise.libelle =  this.formMarchandise.value.libelle;
    this.marchandise.prixAchat =  this.formMarchandise.value.prixAchat;
    this.marchandise.prixVente =  this.formMarchandise.value.prixVente;
    if (this.marchandise.id) {
      this.update();
    } else {
      this.save();
    }
  }

  save(): void {
    this.marchandiseService.create(this.marchandise).subscribe({
      next: response => {
        if (response.body === null || !response.body.id) {
          this.messageService.add({ severity: 'error', detail: `Une erreur est survenue lors de la création de la marchandise !` });
        } else {
          this.dialogRef.close(response.body);
        }
      },
      error: err => {
        const message = err?.error?.detail ? err?.error?.detail : `Une erreur est survenue lors de la création de la marchandise !`;
        this.messageService.add({ severity: 'error', detail: message });
      }
    });
  }

  update(): void {
    this.marchandiseService.update(this.marchandise).subscribe({
      next: response => {
        if (response.body === null || !response.body.id) {
          this.messageService.add({ severity: 'error', detail: `Une erreur est survenue lors de la modification de la marchandise !` });
        } else {
          this.dialogRef.close(response.body);
        }
      },
      error: err => {
        const message = err?.error?.detail ? err?.error?.detail : `Une erreur est survenue lors de la modification de la marchandise !`;
        this.messageService.add({ severity: 'error', detail: message });
      }
    });
  }
}
