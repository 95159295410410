<form [formGroup]="formDepense" (ngSubmit)="onGetDepenseInfos()">
    <div class="formgrid">
        <div class="field p-fluid">
            <label>Type de dépense <span class="text-danger">*</span></label>
            <p-dropdown *ngIf="typeDeDepenses$ | async as typeDeDepenses" [options]="typeDeDepenses"
                        [style]="{'width':'100%'}"
                        name="typeDeDepense"
                        formControlName="typeDeDepense"
                        placeholder="Sélectionner le type de dépense"
                        emptyMessage="Aucune type de dépense trouvé"
                        optionLabel="libelleFrancais"
                        dataKey="id"
                        appendTo="body"
                        class="mb-3"
                        [showClear]="true">
            </p-dropdown>
        </div>

        <div class="field p-fluid">
            <label>Montant <span class="text-danger">*</span></label>
            <input name="montant" formControlName="montant" type="text" mask="separator.0" thousandSeparator=" " pInputText>
        </div>

        <div class="text-right mt-3">
            <button pButton pRipple type="reset" (click)="onCloseModal(false)" label="Annuler" class="p-button-outlined p-button-danger mr-3"></button>
            <button [disabled]="formDepense.invalid" pButton pRipple type="submit" label="Enregistrer" class="p-button-outlined p-button-success"></button>
        </div>

    </div>
</form>
