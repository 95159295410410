export class Marchandise {
    constructor(
        public id?: number,
        public code?: string,
        public libelle?: string,
        public prixAchat?: number,
        public prixVente?: number,
        public label?: string,
    ) {
    }
}
