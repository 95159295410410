<form [formGroup]="formMarchandise" (ngSubmit)="onGetMarchandiseInfos()">
    <input name="code" formControlName="code"
           class="mb-3"
           type="text" style="width: 100%" pInputText placeholder="Code de marchandise">

    <input name="libelle" formControlName="libelle"
           class="mb-3 mt-3"
           type="text" style="width: 100%" pInputText placeholder="Nom de la marchandise">

    <input name="prixAchat" formControlName="prixAchat"
           class="mb-3 mt-3" type="text" mask="separator.0" thousandSeparator=" "
           style="width: 100%" pInputText placeholder="Prix d'achat unitaire">

    <input name="prixVente" formControlName="prixVente"
           class="mb-3 mt-3" type="text" mask="separator.0" thousandSeparator=" "
           style="width: 100%" pInputText placeholder="Prix de vente unitaire">

    <div class="text-right mt-3">
        <button pButton pRipple type="reset" label="Annuler" class="p-button-outlined p-button-danger mr-3"></button>
        <button [disabled]="formMarchandise.invalid" pButton pRipple type="submit" label="Enregistrer" class="p-button-outlined p-button-success"></button>
    </div>
</form>
