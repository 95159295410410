import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonModule} from "primeng/button";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {ConfirmationService, MessageService, SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ToastModule} from "primeng/toast";
import {TooltipModule} from "primeng/tooltip";
import {InputTextareaModule} from "primeng/inputtextarea";
import {DropdownModule} from "primeng/dropdown";
import {AutoCompleteModule} from "primeng/autocomplete";
import {MultiSelectModule} from "primeng/multiselect";
import {DialogModule} from "primeng/dialog";
import {CardModule} from "primeng/card";
import {ChipsModule} from "primeng/chips";
import {ProgressBarModule} from "primeng/progressbar";
import {PaginatorModule} from "primeng/paginator";
import {DialogService} from "primeng/dynamicdialog";
import { ChambreStatusColorDirective } from './directives/chambre-status-color.directive';
import { DeviseFormatterPipe } from './pipes/devise-formatter.pipe';
import {CalendarModule} from "primeng/calendar";
import { ReservationStatusColorDirective } from './directives/reservation-status-color.directive';
import {LocationStatusColorDirective} from "./directives/location-status-color.directive";
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import { StatusColorDirective } from './directives/status-color.directive';
import { HasAnyAuthorityDirective } from './directives/has-any-authority.directive';
import {TagModule} from "primeng/tag";
import { AddOrUpdateLigneMarchandiseComponent } from './components/add-or-update-ligne-marchandise/add-or-update-ligne-marchandise.component';
import { AddOrUpdateDepenseComponent } from './components/add-or-update-depense/add-or-update-depense.component';
import {IConfig, NgxMaskModule} from "ngx-mask";
import { ConfirmComponent } from './components/confirm/confirm.component';
import {NgxLoadingModule} from "ngx-loading";

const maskConfigFunction: () => Partial<IConfig> = () => {
    return {
        validation: false,
    };
};

@NgModule({
    declarations: [
        ChambreStatusColorDirective,
        DeviseFormatterPipe,
        ReservationStatusColorDirective,
        LocationStatusColorDirective,
        PdfViewerComponent,
        StatusColorDirective,
        HasAnyAuthorityDirective,
        AddOrUpdateLigneMarchandiseComponent,
        AddOrUpdateDepenseComponent,
        ConfirmComponent,
    ],
  imports: [
    CommonModule,
    ButtonModule,
    ConfirmDialogModule,
    InputTextModule,
    ReactiveFormsModule,
    RippleModule,
    SharedModule,
    TableModule,
    ToastModule,
    TooltipModule,
    InputTextareaModule,
    DropdownModule,
    AutoCompleteModule,
    MultiSelectModule,
    DialogModule,
    CardModule,
    ChipsModule,
    FormsModule,
    ProgressBarModule,
    PaginatorModule,
    CalendarModule,
    NgxExtendedPdfViewerModule,
    TagModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    NgxLoadingModule,
  ],
    exports: [
        CommonModule,
        ButtonModule,
        ConfirmDialogModule,
        InputTextModule,
        ReactiveFormsModule,
        RippleModule,
        SharedModule,
        TableModule,
        ToastModule,
        TooltipModule,
        InputTextareaModule,
        DropdownModule,
        AutoCompleteModule,
        MultiSelectModule,
        DialogModule,
        CardModule,
        ChipsModule,
        FormsModule,
        ProgressBarModule,
        PaginatorModule,
        ChambreStatusColorDirective,
        DeviseFormatterPipe,
        CalendarModule,
        ReservationStatusColorDirective,
        LocationStatusColorDirective,
        StatusColorDirective,
        HasAnyAuthorityDirective,
        TagModule,
        NgxMaskModule,
    ],
    providers: [
        ConfirmationService,
        MessageService,
        DialogService,
    ],
})
export class SharedUtilsModule { }
