import {Component, OnInit} from '@angular/core';
import {TypeDeDepense} from "../../../features/models/type-de-depense.model";
import {Depense} from "../../../features/models/depense.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Observable} from "rxjs";
import {TypeDeDepenseService} from "../../../features/components/parametres/type-de-depenses/type-de-depense.service";

@Component({
  selector: 'app-add-or-update-depense',
  templateUrl: './add-or-update-depense.component.html',
  styleUrls: ['./add-or-update-depense.component.scss']
})
export class AddOrUpdateDepenseComponent implements OnInit {
  typeDeDepenses$: Observable<TypeDeDepense[]> = new Observable<TypeDeDepense[]>();
  depense!: Depense;
  formDepense!: FormGroup;

  constructor(
      private fb: FormBuilder,
      private dynamicDialogConfig: DynamicDialogConfig,
      private dialogRef: DynamicDialogRef,
      private typeDeDepenseService: TypeDeDepenseService,
  ) {
  }

  ngOnInit() {
    this.typeDeDepenses$ = this.typeDeDepenseService.typeDeDepenses$;
    this.depense = this.dynamicDialogConfig.data !== undefined ? this.dynamicDialogConfig.data : new Depense();
    this.createFormGroup();
  }

  createFormGroup(): void {
    this.formDepense = this.fb.group({
      typeDeDepense: [this.depense.typeDepense, Validators.required],
      montant: [this.depense.montant, Validators.required],
    });
  }

  onGetDepenseInfos() {
    this.depense.typeDepense = this.formDepense.value.typeDeDepense;
    this.depense.montant = Number(this.formDepense.value.montant);
    this.onCloseModal(true);
  }

  onCloseModal(withValue: boolean) {
    this.dialogRef.close(withValue ? this.depense : null);
  }
}
