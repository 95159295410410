import {Cheque} from "./cheque.model";
import {Banque} from "./banque.model";

export class Avance {
    constructor(
        public id?: number,
        public montant?: number,
        public motif?: string,
        public entityId?: number,
        public entityName?: string,
        public typeAvance?: string,
        public numeroFacture?: string,
        public clientId?: number,
        public clientName?: string,
        public fullCriteria?: string,
        public createdDate?: Date,
        public modePaiement?: string,
        public cheque?: Cheque,
        public date?: Date,
        public deposant?: string,
        public emetteur?: string,
        public numeroCompteBanque?: string,
        public banque?: Banque,
    ) {
    }
}
